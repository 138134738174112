body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    /*-webkit-font-smoothing: antialiased;*/
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: "BiomeProSemiBold";
    src: local("BiomeProSemiBold"),
    url("./fonts/BiomeProSemiBold.eot?#iefix")format("embedded-opentype"),
    url("./fonts/BiomeProSemiBold.woff") format("woff"),
    url("./fonts/BiomeProSemiBold.ttf")format("truetype");
    font-weight:normal;
    font-style:normal;
    font-display:swap;
}
